import clsx from 'clsx';
import React from 'react';

import { BaseInput, BaseInputProps } from './BaseInput';

export interface TextFieldProps extends Omit<BaseInputProps, 'type' | 'size'> {
  error?: boolean;
  size?: 'small' | 'large';
  contentBefore?: React.ReactNode;
  contentAfter?: React.ReactNode;
}

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    const {
      error,
      className,
      size = 'large',
      disabled,
      contentBefore,
      contentAfter,
      ...rest
    } = props;

    const wrapperClassList = [
      'flex',
      'border-solid',
      'flex-shrink-0',
      'items-center',
      'gap-[10px]',
      'rounded-[4px]',
      'px-[12px]',
      'py-[8px]',
      'align-middle',
      'text-input-shadow'
    ];

    const errorClassList = {
      'border-2': error,
      'border-blush': error
    };

    const disabledClassList = {
      'cursor-not-allowed': disabled,
      'bg-white': !disabled,
      'bg-[#e0e0e0]': disabled,
      'text-[#a6a6a6]': disabled
    };

    const sizeClass = {
      'h-[40px]': size === 'small',
      'h-[58px]': size === 'large'
    };

    const inputClasses = [
      'flex-[1_0_0]',
      'bg-transparent',
      'outline-none',
      'w-full'
    ];
    return (
      <div
        className={clsx(
          wrapperClassList,
          disabledClassList,
          errorClassList,
          sizeClass,
          className
        )}
      >
        {contentBefore}
        <BaseInput
          ref={ref}
          disabled={disabled}
          className={clsx(inputClasses, disabledClassList)}
          type="text"
          {...rest}
        />
        {contentAfter}
      </div>
    );
  }
);
