import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useSelector } from 'react-redux';

import { ContainedButton } from '../../components/buttons';
import { SHOPIFY_PRODUCT_TAG } from '../../config';
import { findProductVariant } from '../../utils';
import { AppStates } from '../../types';

import { selectChosenUpsellItems } from '../upsells/store/upsellsSlice';
import {
  selectCartItem,
  selectItemProperties,
  selectSlots
} from '../item/store/itemSlice';
import { useFontsAndFields } from '../fonts/TextAndFont.hooks';
import { useIconsAndLabels } from '../iconAndLabel/IconAndLabelHooks';
import { useMaterials } from '../clothingType/Materials.hooks';
import { useGetProductQuery } from '../api';

import {
  buildPrimaryApiCartItem,
  buildUpsellApiCartItem,
  // deleteApiCartItem, // not used but leaving it here for reference, see below
  getQuantityProperty,
  sendApiCartItem,
  updateApiCartItem
} from './utils';
import { useLabelShape } from '../LabelShapes/LabelShapeHooks';
import { useRootSelector } from '../../store';
import { CartItemState } from '../app/Configurator';

const { ADDED_TO_CART } = AppStates;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-var
  var Shopify: any;
}

type ShopifyCartItemUpdate = {
  id: number;
  quantity: number;
  properties: Record<string, string>;
};

interface AddToCartButtonProps {
  productTag?: string;
  style?: React.CSSProperties;
  cartItemId?: string;
  isReadyForCart: boolean;
  setAppState: Dispatch<SetStateAction<AppStates>>;
  onClick?: () => void;
  preSelectedUpsells?: CartItemState[];
}

export function AddToCartButton({
  productTag,
  style,
  cartItemId,
  isReadyForCart,
  setAppState,
  onClick,
  preSelectedUpsells = []
}: AddToCartButtonProps) {
  const legacyItemKey = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const legacyItemKey = params.get('legacy_item_key') || undefined;
    return legacyItemKey;
  }, []);

  const updatingCartItem = useMemo(
    () => !!(cartItemId || legacyItemKey),
    [cartItemId, legacyItemKey]
  );
  const properties = useSelector(selectItemProperties);
  const primaryCartItemProperties = useSelector(selectCartItem);
  const chosenUpsellItems = useSelector(selectChosenUpsellItems);
  const [buttonText, setButtonText] = useState(
    updatingCartItem ? 'Save' : 'Add to Cart'
  );
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    setButtonText(updatingCartItem ? 'Save' : 'Add to Cart');
  }, [updatingCartItem]);

  const { fonts, hideFontsAndFields } = useFontsAndFields();
  const { showIcons, labelHasMultipleVariants } = useIconsAndLabels();
  const { showLabelShapes } = useLabelShape();
  const { materials } = useMaterials();
  const { data: apiProduct } = useGetProductQuery(
    productTag || SHOPIFY_PRODUCT_TAG
  );
  const slots = useRootSelector(selectSlots);

  const sendItemToCart = useCallback(async () => {
    const primaryApiCartItem = buildPrimaryApiCartItem(properties, apiProduct);

    let primaryCartItemId;
    try {
      if (cartItemId) {
        primaryCartItemId = await updateApiCartItem(
          cartItemId,
          primaryApiCartItem
        );
      } else {
        primaryCartItemId = await sendApiCartItem(primaryApiCartItem);
      }
    } catch (error) {
      setButtonText('Error adding to cart');
      setButtonDisabled(true);
      return;
    }

    const cartItemIdPromises = [];
    const upsellShopifyCartItems: ShopifyCartItemUpdate[] = [];
    const upsellUpdateProperties = new Map<string, Record<string, string>>();
    for (const chosenUpsell of chosenUpsellItems) {
      const { upsell, material, cartItemId: upsellCartItemId } = chosenUpsell;

      const upsellProperties: { [key: string]: string } = {};

      const labelQuantity = upsell.label_slots.reduce((acc, slot) => {
        const { slot_options } = slot;
        const [option] = slot_options;
        return (acc += option?.quantity ?? 0);
      }, 0);

      upsellProperties.Labels = labelQuantity.toString();

      const { fields } = properties;
      const configuredTextFields = Object.values(fields);

      // Name or Full Name must be added first
      const prioritizedDisplayNames = ['Name', 'Full Name'];
      const prioritizedTextField = configuredTextFields.find(field =>
        prioritizedDisplayNames.includes(field.display_name!)
      );

      if (prioritizedTextField) {
        const { display_name, value } = prioritizedTextField;
        upsellProperties[display_name!] = value;
      }

      for (const { display_name, value } of configuredTextFields) {
        const isAlreadyAdded =
          prioritizedTextField &&
          display_name === prioritizedTextField.display_name;

        if (isAlreadyAdded) continue;

        upsellProperties[display_name!] = value;
      }

      const { style, palette, font, icon } = properties;

      upsellProperties.Style = style!.display_name;
      upsellProperties.Color = palette!.display_name;

      if (fonts?.length > 1) {
        upsellProperties.Font = font!.display_name;
      }

      if (showIcons) {
        upsellProperties.Icon = icon!.display_name;
      }

      upsellProperties['Clothing Type'] = material?.display_name;

      upsellProperties._order_system = 'NEW';
      upsellProperties._parent_item_id = primaryCartItemId;

      const upsellApiCartItemData = buildUpsellApiCartItem(
        chosenUpsell,
        properties,
        primaryApiCartItem
      );

      const fetchAndMountCartItemId = async () => {
        if (upsellCartItemId) {
          await updateApiCartItem(upsellCartItemId, upsellApiCartItemData);
          upsellProperties._cart_item_id = upsellCartItemId;
        } else {
          const upsellCartItemId = await sendApiCartItem(upsellApiCartItemData);
          upsellProperties._cart_item_id = upsellCartItemId;
        }
      };

      if (!upsellCartItemId) {
        upsellShopifyCartItems.push({
          id: upsell.shopify_id!,
          quantity: 1,
          properties: upsellProperties
        });
      } else {
        upsellUpdateProperties.set(upsellCartItemId, upsellProperties);
      }

      cartItemIdPromises.push(fetchAndMountCartItemId());
    }

    // the order of how the following properties are added controls
    // the order in which they are displayed in the cart

    // arrange cart properties for display
    const {
      Font,
      ['Clothing Type']: clothingType,
      Icon,
      Style,
      Palette,
      ...otherPrimaryCartItemProperties
    } = primaryCartItemProperties;

    // next show the Field Names. "Name" or "Full Name" should always be first.
    const fieldNames: { [key: string]: string | undefined } = {};

    const hasNameField = otherPrimaryCartItemProperties.Name;
    if (hasNameField) {
      fieldNames.Name = otherPrimaryCartItemProperties.Name;
      delete otherPrimaryCartItemProperties.Name;
    }

    const hasFullNameField = otherPrimaryCartItemProperties['Full Name'];
    if (hasFullNameField) {
      fieldNames['Full Name'] = otherPrimaryCartItemProperties['Full Name'];
      delete otherPrimaryCartItemProperties['Full Name'];
    }

    if (!hideFontsAndFields) {
      Object.entries(otherPrimaryCartItemProperties).forEach(([key, value]) => {
        if (!key.startsWith('_')) {
          fieldNames[key] = value;
          delete otherPrimaryCartItemProperties[key];
        }
      });
    }

    const userProperties: { [key: string]: string | undefined } = {
      Style,
      Color: Palette
    };

    // Show the selected font if there were multiple fonts to choose from
    if (fonts?.length > 1) {
      userProperties.Font = Font;
    }

    // Show the selected icon if the icon selection step is shown
    if (showIcons) {
      userProperties.Icon = Icon;
    }

    // If there is a LabelShape _choice_, show that;
    // For each slot:
    //   If there is a label variant _choice_, show that:
    const shapes = [];
    if (showLabelShapes && properties.labelShape?.display_name) {
      shapes.push(properties.labelShape.display_name);
    }
    apiProduct?.label_slots?.map(labelSlot => {
      const labelId = slots?.[labelSlot.product_label_slot_id]?.labelId;
      const label = apiProduct.labels.find(l => l.label_id === labelId);
      const isIterative = label?.iterative_style_ids?.includes(
        properties?.style?.style_id ?? ''
      );
      const hasMultipleVariants = labelHasMultipleVariants[labelId || ''];

      if (hasMultipleVariants && !isIterative) {
        // Add selected variant
        const variantId =
          properties.slots[labelSlot.product_label_slot_id].variantId;
        const variant = label?.variants.find(
          v => v.label_variant_id === variantId
        );
        if (variant?.system_name) shapes.push(variant.system_name);
      }
    });

    if (shapes.length) {
      userProperties.Shape = shapes.join(', ');
    }

    // Show the selected material if there were multiple materials to choose from
    if (materials?.length > 1) {
      userProperties['Clothing Type'] = clothingType;
    }

    // get the number of labels.
    const quantity = getQuantityProperty(properties.slots, apiProduct);

    const formattedItemProperties = {
      Labels: quantity.toString(),
      ...fieldNames,
      ...userProperties,
      ...otherPrimaryCartItemProperties,
      _order_system: 'NEW',
      _cart_item_id: primaryCartItemId
    };

    const { labelShape, palette, style } = properties;
    const productVariant = findProductVariant(labelShape, palette, style);

    const primaryProductItem = {
      id: productVariant?.id,
      quantity: 1,
      properties: formattedItemProperties
    };

    const productItems = cartItemId ? [] : [primaryProductItem];
    try {
      await Promise.all(cartItemIdPromises);
    } catch (error) {
      setButtonText('Error adding to cart');
      setButtonDisabled(true);
      return;
    }
    if (cartItemId) {
      const params = new URLSearchParams(window.location.search);
      const key = params.get('key')!;
      const [variantId, _hash] = key.split(':');

      // if the key in the URL does not match the variant id, remove the cart item
      // and re-add the correct one
      if (Number(variantId) !== primaryProductItem.id) {
        await removeShopifyCartItem(key);
        await addShopifyCartItem(primaryProductItem);
      } else {
        await updateShopifyCartItem(key, primaryProductItem);
      }
    }

    const items = [...productItems, ...upsellShopifyCartItems];

    if (items.length) {
      const response = await addShopifyCartItems(items);

      if (legacyItemKey) {
        await removeShopifyCartItem(legacyItemKey);
      }

      if (!response.ok) {
        const json = await response.json();
        console.error('error adding to cart', json);
        setButtonText('Error adding to cart');
        setButtonDisabled(true);
        return;
      }
    }

    if (preSelectedUpsells?.length) {
      for (const upsell of preSelectedUpsells) {
        if (upsell.cart_item_id) {
          const properties = upsellUpdateProperties.get(upsell.cart_item_id);
          if (properties) {
            await fetch('/cart/change.js', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: upsell.shopify_key,
                properties: properties
              })
            });
          } else {
            await removeShopifyCartItem(upsell.shopify_key!);
            // since cart items are not connected to an order until the customer
            // checks out, there is no risk of creating a pdf for an upsell that
            // is not part of the order. For this reason, we do not need to delete
            // the upsell cart item from the database. commenting this function
            // out but leaving it here for reference.

            // await deleteApiCartItem(upsell.cart_item_id);
          }
        }
      }
    }

    setAppState(ADDED_TO_CART);
  }, [
    properties,
    apiProduct,
    primaryCartItemProperties,
    hideFontsAndFields,
    fonts?.length,
    showIcons,
    showLabelShapes,
    materials?.length,
    cartItemId,
    preSelectedUpsells,
    setAppState,
    chosenUpsellItems,
    slots,
    labelHasMultipleVariants,
    legacyItemKey
  ]);

  const handleClick = useCallback(async () => {
    onClick?.();
    if (isReadyForCart) {
      setButtonText(updatingCartItem ? 'Saving' : 'Adding to Cart');
      setButtonDisabled(true);
      sendItemToCart();
    }
  }, [updatingCartItem, isReadyForCart, onClick, sendItemToCart]);

  return (
    <ContainedButton
      style={style}
      size="large"
      className="mt-5 flex-1"
      disabled={buttonDisabled}
      onClick={handleClick}
    >
      {buttonText}
    </ContainedButton>
  );
}

function addShopifyCartItems(items: object[]) {
  return fetch('/cart/add.js', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ items })
  });
}

function updateShopifyCartItem(key: string, primaryProductItem: object) {
  return fetch('/cart/change.js', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...primaryProductItem, id: key })
  });
}

function addShopifyCartItem(primaryProductItem: object) {
  return addShopifyCartItems([primaryProductItem]);
}

function removeShopifyCartItem(key: string) {
  return fetch('/cart/change.js', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id: key, quantity: 0 })
  });
}
