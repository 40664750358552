import React from 'react';
import clsx from 'clsx';

import { ModalComponent } from '../../modals';
import { ContainedButton } from '../../buttons';
import { SizeEnum } from '../../../types';

interface EditVdpCodeModalProps {
  isOpen: boolean;
  vdpCode: string;
  newVdpCode: string;
  handleClose: () => void;
  handleSave: () => void;
  error: boolean;
}

export const EditVdpCodeModalComponent: React.FC<EditVdpCodeModalProps> = ({
  isOpen,
  vdpCode,
  newVdpCode,
  handleClose,
  handleSave,
  error
}) => {
  const modalClassNames = [
    'bg-white',
    'text-center',
    'rounded',
    'h-auto',
    'w-[300px]',
    'text-navy'
  ];
  const overlayClassNames = [
    'fixed',
    'top-0',
    'left-0',
    'right-0',
    'bottom-0',
    'bg-black',
    'bg-opacity-50',
    'flex',
    'justify-center',
    'items-center'
  ];
  return (
    <ModalComponent
      className={clsx(modalClassNames)}
      overlayClassName={clsx(overlayClassNames)}
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Edit VDP Code"
      overrideClassName
      overrideOverlayClassName
    >
      {/* Modal Header */}
      <div className="bg-navy w-[100%] rounded-t p-[10px] text-white">
        <h2>Edit VDP Code</h2>
      </div>
      {/* Modal Body */}
      <div className="m-[20px]">
        {error ? (
          <p className="text-blush m-2 flex-1">
            Error:{' '}
            <span className="text-black">
              VDP Code must be 4 or 7 uppercase letters.
            </span>
          </p>
        ) : (
          <span className="text-black">
            <p className="m-2 flex-1">
              Current VDP Code: <span className="text-blush">{vdpCode}</span>
            </p>
            <p className="m-2 flex-1">
              New VDP Code: <span className="text-aqua">{newVdpCode}</span>
            </p>
          </span>
        )}
      </div>
      {/* Modal Footer */}
      <div className="bg-light-blue rounded-b p-[10px]">
        {error ? null : (
          <ContainedButton
            className="bg-navy m-2 mb-1 flex-1 text-white"
            onClick={handleSave}
            color="secondary"
            size={SizeEnum.small}
          >
            Update
          </ContainedButton>
        )}
        <ContainedButton
          className="bg-navy m-2 mb-1 flex-1 text-white"
          onClick={handleClose}
          color="secondary"
          size={SizeEnum.small}
        >
          Cancel
        </ContainedButton>
      </div>
    </ModalComponent>
  );
};
