import { useState } from 'react';
import clsx from 'clsx';

import { ContainedButton } from '../../../components/buttons';
import { TextField } from '../../../components/inputs';
import { EditVdpCodeModalComponent } from '../../../components/modals';
import { SizeEnum } from '../../../types';

interface EditVdpCodeComponentProps {
  vdpCode: string;
  handleUpdateVdpCode: (vdpCode: string) => void;
}

/*
  * EditVdpCode Component

  * EditVdpCode component is a component that allows the user to edit the VDP code on a feature.
  * This component is intended to be feature agnostic and can be used in any feature that requires a VDP code.
  * Note that this component should be implemented in conjunction with the relevant changes to the api endpoint 
  * and the feature component.

  * Features:
  * It has a text field for entering the VDP code
  * It has a button to save the VDP code
  * It has a button to edit the VDP code
  * It has a modal to confirm the VDP code change

  * Required Props:
  * vdpCode: string - the current VDP code. This should be pulled from the feature object or set to an empty string.
  * handleUpdateVdpCode: function - a function that updates the VDP code in the feature object. This function should 
  * be passed down from the feature component.
  
  * Example:
  * export function FeatureComponent() {
  * 
  *   const [feature, setFeature] = useState<Feature>({
  *     vdpCode: 'ABCD',
  *    });
  * 
  *   const handleUpdateVdpCode = (vdpCode: string) => {
  *     setFeature({ ...prevFeature, vdpCode });
  *   };
  * 
  *   return (
  *     <EditVdpCode
  *       vdpCode={vdpCode}
  *       handleUpdateVdpCode={handleUpdateVdpCode}
  *     />
  *   ); 
  * }
*/
export function EditVdpCodeComponent({
  vdpCode,
  handleUpdateVdpCode
}: EditVdpCodeComponentProps) {
  const [isEditingVdpCode, setIsEditingVdpCode] = useState<boolean>(false);
  const [isSavingVdpCode, setIsSavingVdpCode] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [text, setText] = useState<string>(vdpCode);

  const handleEditVdpCode = () => {
    if (isEditingVdpCode) {
      if (vdpCode !== text) {
        toggleIsSavingVdpCode();
      }
    }
    setIsEditingVdpCode(prevState => {
      return !prevState;
    });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateVdpCode(e.target.value);
    setText(e.target.value.toUpperCase());
  };

  const handleSaveVdpCode = () => {
    handleUpdateVdpCode(text);
    toggleIsSavingVdpCode();
  };

  const validateVdpCode = (newCode: string) =>
    newCode.length == 4 || newCode.length == 7
      ? setError(false)
      : setError(true);

  const toggleIsSavingVdpCode = () => {
    setIsSavingVdpCode(prevState => {
      return !prevState;
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <TextField
          className={clsx('small')}
          id="vdp-code-input"
          disabled={!isEditingVdpCode}
          value={text}
          onChange={handleTextChange}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              handleEditVdpCode();
            }
          }}
          error={error}
          size="small"
          contentBefore={<p>VDP Code:</p>}
          contentAfter={
            <ContainedButton
              size={SizeEnum.small}
              onClick={() => {
                handleEditVdpCode();
              }}
            >
              {isEditingVdpCode ? 'Save' : 'Edit'}
            </ContainedButton>
          }
          style={{ width: '50px' }}
        />
        <EditVdpCodeModalComponent
          isOpen={isSavingVdpCode}
          vdpCode={vdpCode || ''}
          newVdpCode={text}
          handleClose={toggleIsSavingVdpCode}
          handleSave={handleSaveVdpCode}
          error={error}
        />
      </div>
    </div>
  );
}
