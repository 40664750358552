import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import { ContainedButton } from '../../../components/buttons';
import { AppStates } from '../../../types';

interface ShowUpsellsButtonProps {
  isReadyForCart: boolean;
  cartItemId?: string;
  setAppState: Dispatch<SetStateAction<AppStates>>;
  onClick?: () => void;
}

export function ShowUpsellsButton({
  isReadyForCart,
  setAppState,
  onClick,
  cartItemId
}: ShowUpsellsButtonProps) {
  const legacyItemKey = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const legacyItemKey = params.get('legacy_item_key') || undefined;
    return legacyItemKey;
  }, []);

  const updatingCartItem = useMemo(() => {
    return !!(cartItemId || legacyItemKey);
  }, [cartItemId, legacyItemKey]);

  const [buttonText, setButtonText] = useState(
    updatingCartItem ? 'Continue' : 'Add to Cart'
  );

  useEffect(() => {
    if (updatingCartItem) {
      setButtonText('Continue');
    } else {
      setButtonText('Add to Cart');
    }
  }, [updatingCartItem]);

  const handleClick = useCallback(() => {
    onClick?.();
    if (isReadyForCart) {
      setAppState(AppStates.SHOW_UPSELLS);
    }
  }, [setAppState, isReadyForCart, onClick]);

  return (
    <ContainedButton size="large" className="mt-5" onClick={handleClick}>
      {buttonText}
    </ContainedButton>
  );
}
